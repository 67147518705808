import React from "react";
import { Translate } from "react-auto-translate";
import { useHistory } from "react-router-dom";
import moment from "moment";

import defaultBanner from "./../../assets/images/default_image.svg";

import "./CardInformationCommon.scss";
import { TIME_FORMAT } from "../../settings/formatDateTime";

export default function CardInformationCommon({ data }) {
  const { header, image, title, description, id, path, date } = data;
  const history = useHistory();

  const handleInformationDetail = (id) => {
    history.push(`${path}${id}`);
  };
  return (
    <div className="commonCard">
      <div className="commonCard--image">
        <img
          src={
            image ? `${process.env.REACT_APP_ENDPOINT}${image}` : defaultBanner
          }
          alt="banner infomation"
        />
      </div>

      {id && (
        <p
          onClick={() => handleInformationDetail(id)}
          className="commonCard--title"
        >
          <p>{title}</p>
        </p>
      )}
      {description && (
        <p className="commonCard--description">
          <Translate>{description}</Translate>
        </p>
      )}
      {date && (
        <div className="commonCard--date">
          <hr />
          <p>
            {moment(date, ["YYYY-MM-DD", "DD.MM.YYYY"]).format(
              TIME_FORMAT?.DOT_DATE_FORMAT
            )}
          </p>
        </div>
      )}
    </div>
  );
}
