import React from "react";
import Slider from "react-slick";

import CardInformationCommon from "../CardInformationCommon";

import prevIcon from "../../assets/images/icons/prev-icon.svg";
import nextIcon from "../../assets/images/icons/next-icon.svg";
import "./../SliderCommon/slider-common.scss";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={prevIcon} alt="arrow-prev-logo" width={32} height={32} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={nextIcon} alt="arrow-next-logo" width={32} height={32} />
    </div>
  );
}

function SliderCommon({ dataRelated, path, titleHeader }) {
  const numberdataSlider = dataRelated.length;
  const settings = {
    dots: false,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: numberdataSlider <= 4 ? false : true,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: numberdataSlider <= 4 ? null : <SampleNextArrow />,
    nextArrow: numberdataSlider <= 4 ? null : <SamplePrevArrow />,
    vertical: false,
    responsive: [
      {
        breakpoint: 1199.98,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
          infinite: numberdataSlider <= 3 ? false : true,
          prevArrow: numberdataSlider <= 3 ? null : <SampleNextArrow />,
          nextArrow: numberdataSlider <= 3 ? null : <SamplePrevArrow />,
        },
      },
      {
        breakpoint: 576.01,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          infinite: numberdataSlider <= 2 ? false : true,
          prevArrow: numberdataSlider <= 2 ? null : <SampleNextArrow />,
          nextArrow: numberdataSlider <= 2 ? null : <SamplePrevArrow />,
        },
      },
      {
        breakpoint: 376,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          infinite: numberdataSlider <= 1 ? false : true,
          prevArrow: numberdataSlider <= 1 ? null : <SampleNextArrow />,
          nextArrow: numberdataSlider <= 1 ? null : <SamplePrevArrow />,
        },
      },
    ],
  };

  return (
    <>
      {dataRelated?.length !== 0 ? (
        <div className="wrapSlider">
          <div className="wrapSlider__header">
            <p>{titleHeader}</p>
            <hr />
          </div>
          {dataRelated?.length > 1 ? (
            <div className="wrapSlider__container">
              <Slider {...settings}>
                {dataRelated?.map((item) => (
                  <div key={item?.id} className="wrapSlider__card">
                    <CardInformationCommon
                      data={{
                        header: item?.menu_name,
                        image:
                          item?.thumbnail ||
                          item?.image ||
                          item?.attach_files?.[0],
                        title: item?.title,
                        description: item?.description,
                        id: item?.id,
                        date: item?.created_at,
                        path: path,
                      }}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <div className="wrapSlider__small">
              <CardInformationCommon
                data={{
                  header: dataRelated?.[0].menu_name,
                  image:
                    dataRelated?.[0].thumbnail ||
                    dataRelated?.[0].image ||
                    dataRelated?.[0].attach_files?.[0],
                  title: dataRelated?.[0].title,
                  description: dataRelated?.[0].description,
                  id: dataRelated?.[0].id,
                  date: dataRelated?.[0].created_at,
                  path: path,
                }}
              />
            </div>
          )}
        </div>
      ) : null}
    </>
  );
}

export default SliderCommon;
