import ClubInArea from "../container/ClubInArea";
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Route, Switch, useLocation } from "react-router-dom";
import ButtonScrollToTop from "../components/ButtonScrollToTop";
import Footer from "../components/Footer_v2";
import Header from "../components/Header_V2";
import ScrollToTop from "../components/ScrollToTopWrapper";
import ActivityUpdate from "../container/ActivityUpdate";
import CategoryDocument from "../container/CategoryDocument";
import CategoryDocumentDetail from "../container/CategoryDocumentDetail";
import CategorySkill from "../container/CategorySkill";
import CategorySkillDetail from "../container/CategorySkillDetail";
import ClubDetail from "../container/ClubDetail";
import ContactUs from "../container/Contact";
import EventDetail from "../container/DetailEvents";
import DetailPost from "../container/DetailPost";
import EventPage from "../container/Events";
import HistoryUpdate from "../container/HistoryUpdate";
import Homepage from "../container/Home/index";
import InformationDetail from "../container/InformationDetail";
import KyThi from "../container/KyThi/";
import KyThiDetail from "../container/KyThi/KyThiDetail";
import Library from "../container/Library";
import DetailsMap from "../container/Maps/Details";
import MapDetail from "../container/Maps/Detail_v2";
import Newspage_v2 from "../container/News_v2";
import Page404 from "../container/Page404";
import Register from "../container/Register/";
import SearchList from "../container/Search/List";
import ClubGroup from "../container/ClubGroup";
import Information from "./../container/Information/index";
import {
  URL_ACTIVE_PAGE,
  URL_HISTORY_PAGE,
  URL_TECHNICAL_PAGE,
} from "../utils/constants";

import ContactV2 from "../container/ContactV2";
import DetailSearch from "../container/SearchDetail";
import StudentSearch from "../container/SearchStudent/index";
import LibraryPage from "../container/LibraryPage";
import ActivityPage from "../container/ActivityPage";
import GroupTechniques from "../container/groupTechniques";
import Techniques from "../container/groupTechniques/Techniques/techniques";
import TechniquesDetails from "../container/groupTechniques/TechniquesDetails/techniques";
import ActivityPageDetail from "../container/ActivityPageDetail";
import InformationByTeacher from "../container/InformationByTeacher";

const Routes = () => {
  const { t } = useTranslation();
  const menus = [
    {
      id: 1,
      name: t("header.history"),
      url: `/history/tu-okinawa-den-tokyo`,
      subMenu: [
        {
          id: 1,
          name: t("history.tuOkinawaDenTokyo"),
          url: `/history/${URL_HISTORY_PAGE.tuOkinawaDenTokyo}`,
        },
        {
          id: 2,
          name: t("history.tuNhatBanDenVietNam"),
          url: `/history/${URL_HISTORY_PAGE.tuNhatBanDenVietNam}`,
        },
        {
          id: 3,
          name: t("history.tuDaoDuongSuzuki"),
          url: `/history/${URL_HISTORY_PAGE.tuDaoDuongSuzuki}`,
        },

        {
          id: 4,
          name: t("history.tuSuzuchoKarateDo"),
          url: `/history/${URL_HISTORY_PAGE.tuSuzuchoKarateDo}`,
        },
      ],
    },
    {
      id: 2,
      name: t("header.phanDuong"),
      url: "/maps",
    },
    {
      id: 3,
      name: t("tech-active.technical"),
      url: "/technical/tong-quan",
      subMenu: [
        {
          id: 1,
          name: t("tech-active.tong-quan"),
          url: "/technical/tong-quan",
        },
        {
          id: 2,
          name: t(`tech-active.${URL_TECHNICAL_PAGE.kyThuatCanBan}`),
          url: `/technical/${URL_TECHNICAL_PAGE.kyThuatCanBan}`,
        },
        {
          id: 3,
          name: t(`tech-active.${URL_TECHNICAL_PAGE.kyThuatQuyenPhap}`),
          url: `/technical/${URL_TECHNICAL_PAGE.kyThuatQuyenPhap}`,
        },

        {
          id: 4,
          name: t(`tech-active.${URL_TECHNICAL_PAGE.dauLuyenThiDau}`),
          url: `/technical/${URL_TECHNICAL_PAGE.dauLuyenThiDau}`,
        },
        {
          id: 5,
          name: t(`tech-active.${URL_TECHNICAL_PAGE.chuongTrinhHuanLuyen}`),
          url: `/technical/${URL_TECHNICAL_PAGE.chuongTrinhHuanLuyen}`,
        },

        {
          id: 6,
          name: t(`tech-active.${URL_TECHNICAL_PAGE.quyCheThiDau}`),
          url: `/technical/${URL_TECHNICAL_PAGE.quyCheThiDau}`,
        },
      ],
    },
    {
      id: 4,
      name: t("header.active"),
      url: "/activity/tong-quan",
      subMenu: [
        {
          id: 1,
          name: t("tech-active.tong-quan"),
          url: "/activity/tong-quan",
        },
        {
          id: 2,
          name: t(`tech-active.${URL_ACTIVE_PAGE.lichLuyenTap}`),
          url: `/activity/${URL_ACTIVE_PAGE.lichLuyenTap}`,
        },
        {
          id: 3,
          name: t(`tech-active.${URL_ACTIVE_PAGE.toChucThiDau}`),
          url: `/activity/${URL_ACTIVE_PAGE.toChucThiDau}`,
        },

        {
          id: 4,
          name: t(`tech-active.${URL_ACTIVE_PAGE.hoatDongDaNgoai}`),
          url: `/activity/${URL_ACTIVE_PAGE.hoatDongDaNgoai}`,
        },
        {
          id: 5,
          name: t(`tech-active.${URL_ACTIVE_PAGE.hoatDongTuThien}`),
          url: `/activity/${URL_ACTIVE_PAGE.hoatDongTuThien}`,
        },
        {
          id: 6,
          name: t(`tech-active.${URL_ACTIVE_PAGE.hoatDongKhac}`),
          url: `/activity/${URL_ACTIVE_PAGE.hoatDongKhac}`,
        },
      ],
    },
    {
      id: 8,
      name: t("common.exam"),
      url: "/ky-thi",
    },
    {
      id: 5,
      name: t("header.info"),
      url: "/news",
      subMenu: [
        {
          id: 1,
          name: t("header.info"),
          url: "/news",
        },
        {
          id: 2,
          name: t("header.search"),
          url: "/search",
        },
      ],
    },
    {
      id: 6,
      name: t("header.library"),
      url: "/library/ebook",
      subMenu: [
        {
          id: 1,
          name: t("common.book"),
          url: "/library/ebook",
        },
        {
          id: 2,
          name: t("common.posts"),
          url: "/library/documents",
        },
        {
          id: 3,
          name: t("common.photoLibrary"),
          url: "/library/images",
        },

        {
          id: 4,
          name: "Video",
          url: "/library/videos",
        },
      ],
    },
    {
      id: 9,
      name: t("common.register"),
      url: "/register",
    },
  ];
  const [isShowButton, setIsShowButton] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      setIsShowButton(true);
    } else setIsShowButton(false);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  const location = useLocation();
  const { pathname } = location;

  return (
    <>
      {pathname !== "/register" && <Header listRoute={menus} />}
      <div
        className={`app_wrapper app_wrapper__pt-0 ${
          pathname === "/register" && "app_wrapper__pt-0"
        }`}
      >
        <ScrollToTop>
          <Switch>
            <Route path="/" component={Homepage} exact />
            <Route path="/events" component={EventPage} exact />
            <Route path="/news" component={Newspage_v2} exact />
            <Route path="/library/:id" component={Library} exact />
            <Route path="/maps" component={DetailsMap} exact />
            <Route path="/maps/:id" component={MapDetail} exact />
            <Route path="/search" component={SearchList} exact />
            {/* <Route path='/news/:id' component={NewsDetail} exact /> */}
            <Route path="/news/:id" component={InformationDetail} exact />
            <Route path="/events/:id" component={EventDetail} exact />
            <Route path="/library/posts/:id" component={DetailPost} exact />
            <Route path="/history" component={HistoryUpdate} exact />
            {/* <Route path='/history/:id' component={HistoryUpdate} exact /> */}
            <Route path="/web-news" component={Information} exact />
            <Route path="/web-news/:id" component={InformationDetail} exact />
            <Route
              path="/web-news-by-teacher"
              component={InformationByTeacher}
              exact
            />
            <Route path="/contact" component={ContactUs} exact />
            <Route path="/ky-thi" component={KyThi} exact />
            <Route path="/ky-thi/:id" component={KyThiDetail} exact />
            <Route path="/register" component={Register} exact />
            <Route path="/branchsinareas/" component={ClubGroup} exact />
            <Route path="/branchsinareas/:id" component={ClubInArea} exact />
            <Route path="/club/:id" component={ClubDetail} exact />
            <Route
              path="/document/:idCategory"
              component={CategoryDocument}
              exact
            />
            <Route
              path="/document/:idCategory/detail/:id"
              component={CategoryDocumentDetail}
              exact
            />

            {/* <Route path='/technical/tong-quan' exact>
              <TongQuan type='technical' />
            </Route>
            <Route
              path={`/technical/${URL_TECHNICAL_PAGE.kyThuatCanBan}`}
              exact
            >
              <Technical
                title='ky thuat can ban'
                type='technical'
                menu_id={3}
              />
            </Route>
            <Route
              path={`/technical/${URL_TECHNICAL_PAGE.kyThuatCanBan}/:id`}
              exact
              component={TechnicalDetail}
            />
            <Route
              path={`/technical/${URL_TECHNICAL_PAGE.kyThuatQuyenPhap}`}
              exact
            >
              <Technical
                title='ky thuat quyen phap'
                type='technical'
                menu_id={4}
              />
            </Route>
            <Route
              path={`/technical/${URL_TECHNICAL_PAGE.kyThuatQuyenPhap}/:id`}
              component={TechnicalDetail}
            />
            <Route
              path={`/technical/${URL_TECHNICAL_PAGE.dauLuyenThiDau}`}
              exact
            >
              <Technical
                title='dau luyen va thi dau'
                type='technical'
                menu_id={5}
              />
            </Route>
            <Route
              path={`/technical/${URL_TECHNICAL_PAGE.dauLuyenThiDau}/:id`}
              component={TechnicalDetail}
            />
            <Route
              path={`/technical/${URL_TECHNICAL_PAGE.chuongTrinhHuanLuyen}`}
              exact
            >
              <Technical
                title='chuong trinh huan luyen'
                type='technical'
                menu_id={6}
              />
            </Route>
            <Route
              path={`/technical/${URL_TECHNICAL_PAGE.chuongTrinhHuanLuyen}/:id`}
              component={TechnicalDetail}
            />
            <Route path={`/technical/${URL_TECHNICAL_PAGE.quyCheThiDau}`} exact>
              <TechnicalOther
                title='quy che thi dau va len dang'
                type='technical'
                menu_id={7}
              />
            </Route>
            <Route
              path={`/technical/${URL_TECHNICAL_PAGE.quyCheThiDau}/:id`}
              component={TechnicalDetail}
            /> */}

            <Route path="/skill/:id" component={CategorySkill} exact />
            <Route
              path="/skill/:id/detail/:idDetail"
              component={CategorySkillDetail}
              exact
            />

            <Route path="/activity/:id" component={ActivityUpdate} exact />
            <Route path="/activity-new" component={ActivityPage} exact />
            <Route
              path="/activity-new/:id"
              component={ActivityPageDetail}
              exact
            />

            {/* <Route path='/activity/tong-quan' exact>
              <TongQuan type='activity' />
            </Route>
            <Route path={`/activity/${URL_ACTIVE_PAGE.lichLuyenTap}`} exact>
              <Activity title='lich luyen tap' type='activity' menu_id={8} />
            </Route>
            <Route
              path={`/activity/${URL_ACTIVE_PAGE.lichLuyenTap}/:id`}
              component={ActivityDetail}
            />
            <Route path={`/activity/${URL_ACTIVE_PAGE.toChucThiDau}`} exact>
              <Activity title='to chuc thi dau' type='activity' menu_id={9} />
            </Route>
            <Route
              path={`/activity/${URL_ACTIVE_PAGE.toChucThiDau}/:id`}
              component={ActivityDetail}
            />
            <Route path={`/activity/${URL_ACTIVE_PAGE.hoatDongDaNgoai}`} exact>
              <Activity
                title='hoat dong da ngoai'
                type='activity'
                menu_id={10}
              />
            </Route>
            <Route
              path={`/activity/${URL_ACTIVE_PAGE.hoatDongDaNgoai}/:id`}
              component={ActivityDetail}
            />
            <Route path={`/activity/${URL_ACTIVE_PAGE.hoatDongTuThien}`} exact>
              <Activity
                title='hoat dong tu thien'
                type='activity'
                menu_id={11}
              />
            </Route>
            <Route
              path={`/activity/${URL_ACTIVE_PAGE.hoatDongTuThien}/:id`}
              component={ActivityDetail}
            />
            <Route path={`/activity/${URL_ACTIVE_PAGE.hoatDongKhac}`} exact>
              <Activity title='hoat dong khac' type='activity' menu_id={12} />
            </Route>
            <Route
              path={`/activity/${URL_ACTIVE_PAGE.hoatDongKhac}/:id`}
              component={ActivityDetail}
            /> */}
            <Route path="/detail-search" component={DetailSearch} exact />
            <Route path="/student-search" component={StudentSearch} exact />
            <Route path="/student-search/:id" component={StudentSearch} exact />

            <Route path="/lien-he" component={ContactV2} exact />
            {/* <Route
              path="/thong-tin/:id"
              component={InformationDetailV2}
              exact
            /> */}

            <Route path="/document" component={LibraryPage} exact />
            <Route path="/group-techniques" component={GroupTechniques} exact />
            <Route
              path="/group-techniques/:idTechniques"
              component={Techniques}
              exact
            />
            <Route
              path="/group-techniques/:idTechniques/details/:id"
              component={TechniquesDetails}
              exact
            />

            <Route component={Page404} />
          </Switch>
        </ScrollToTop>
      </div>
      {pathname !== "/register" && (
        <>
          <ButtonScrollToTop show={isShowButton} />
          <Footer listRoute={menus} />
        </>
      )}
    </>
  );
};

export default withTranslation()(Routes);
