import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  URL_API_SEARCH,
  URL_API_BANNERS,
  URL_WEB_TYPICAL_USERS,
} from "../../utils/constants";
import { getDataByID, postData } from "../../utils/service";

import BannerCommon from "../../components/BannerCommon";
import BreadcrumbComponent from "../../components/BreadcrumbCommon";
import StudentSearchDetail from "./StudentSearchDetail";
import SearchCommon from "./SearchCommon";

import "../../assets/styles/search/search.scss";
import "./../SearchStudent/search-student.scss";

const StudentSearch = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [imageBanner, setImageBanner] = useState("");

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

  const { id } = useParams();

  const dataBreadcrumb = id
    ? [
        { title: t("search.home"), url: "/" },
        {
          title: t("search.studentSearch"),
          url: "/student-search",
        },
        { title: data?.data?.[0]?.name },
      ]
    : [
        { title: t("search.home"), url: "/" },
        { title: t("search.studentSearch") },
      ];

  const fetchData = () => {
    if (id) {
      postData(URL_API_SEARCH, { keyword: id, per_page: 10 })
        .then((res) => {
          setData({ data: res?.data?.data, type: "" });
        })
        .catch((err) => err);
    } else {
      postData(URL_WEB_TYPICAL_USERS, { keyword: "", per_page: 10 })
        .then((res) => {
          setData({ data: res?.data?.data, type: "" });
        })
        .catch((err) => err);
    }
  };

  const handleSearch = (values) => {
    postData(URL_API_SEARCH, {
      keyword: values,
      per_page: 10,
    })
      .then((res) => {
        setData({ data: res?.data?.data, type: "search" });
      })
      .catch((err) => err);
  };

  const fetchBanner = () => {
    postData(URL_API_BANNERS, { is_show: true })
      .then((res) => {
        const imageActivity = res?.data?.data?.find(
          (item) => item.type === "SEARCH"
        );
        setImageBanner(
          isMobile ? imageActivity?.image_mobile : imageActivity?.image
        );
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    fetchData();
    fetchBanner();
  }, []);

  return (
    <div>
      <BannerCommon img={imageBanner} text1={t("search.studentSearch")} />
      <div className="container">
        <div className="container__breadcrumb">
          <BreadcrumbComponent dataBreadcrumb={dataBreadcrumb} />
        </div>
        <div className="search__detail">
          <div>
            {!id && (
              <SearchCommon
                placeholder={t("search.placeholder")}
                allowClear
                onSearch={handleSearch}
                enterButton={t("search.search")}
              />
            )}
          </div>
        </div>
        <p className="search__detail__list">
          {id
            ? t("search.studentInformation")
            : data?.type === "search"
            ? t("search.result")
            : t("search.list")}
        </p>

        <div>
          {data?.data?.map((item) => {
            return (
              <StudentSearchDetail
                type={data?.type}
                data={item}
                key={item?.data?.id}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StudentSearch;
