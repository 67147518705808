import React, { useRef, useState } from "react";
import { Avatar, Button, Col, Modal, Row, Table, message } from "antd";
import { useTranslation } from "react-i18next";
import { LinkOutlined, QrcodeOutlined, UserOutlined } from "@ant-design/icons";
import QRCode from "react-qr-code";

import { URL_API_HORNOR } from "../../utils/constants";
import { postData } from "../../utils/service";
import moment from "moment";

import "./../SearchStudent/search-student.scss";

const StudentSearchDetail = ({ type = "", data }) => {
  const { t } = useTranslation();

  const qrRef = useRef();

  const studentData = data;
  const [dataAchievements, setDataAchievements] = useState([]);
  const [showQRCode, setShowQRCode] = useState(false);

  const [openTable, setOpenTable] = useState({
    key: "",
    status: false,
  });

  const baseColumn = [
    {
      title: t("search.numericalOrder"),
      render: (text, record, index) => index + 1,
      responsive: ["md"],
    },
    {
      title: t("search.dateReward"),
      dataIndex: "license_date",
      responsive: ["md"],
      render: (data) => {
        return moment(data).format("DD/MM/YYYY");
      },
    },
    {
      title: t("search.nameAchieve"),
      dataIndex: "title",
    },
    {
      title: t("search.medal"),
      dataIndex: "certificate_image",
      align: "center",

      render: (image, record) => {
        if (typeof image === "string") {
          return (
            <div className="reward_list">
              <a
                data-fancybox={`gallery-${record.id}`}
                href={process.env.REACT_APP_ENDPOINT + image}
              >
                <img
                  src={process.env.REACT_APP_ENDPOINT + image}
                  className="image_reward"
                  alt=""
                />
              </a>
            </div>
          );
        }
        if (typeof image === "object") {
          return (
            <div className="reward_list">
              {image.map((img, index, record) => (
                <span>
                  <a
                    data-fancybox={`gallery-${record.id}`}
                    key={index}
                    href={process.env.REACT_APP_ENDPOINT + img}
                  >
                    <img
                      src={process.env.REACT_APP_ENDPOINT + img}
                      className="image_reward"
                      alt=""
                    />
                  </a>
                  <span>{record?.infoUser?.name}</span>
                </span>
              ))}
            </div>
          );
        }
      },
    },
  ];

  const fetchDataUser = () => {
    postData(URL_API_HORNOR(studentData?.id), { per_page: 10 })
      .then((res) => {
        const result = res?.data?.data;
        setDataAchievements(result);
      })
      .catch((err) => err);
  };

  const handleOpenTable = (id) => {
    fetchDataUser();
    setOpenTable({ key: id, status: !openTable?.status });
  };

  const handleShowQRCode = () => {
    setShowQRCode(!showQRCode);
  };

  const handleCopyLink = (id) => {
    const link = `${window?.location?.origin}/student-search/${id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        return message.success("Sao chép thành công");
      })
      .catch((err) => err);
  };

  const downloadQRCode = () => {
    const svg = qrRef.current.querySelector("svg");
    const svgData = new XMLSerializer().serializeToString(svg);
    const svgBlob = new Blob([svgData], {
      type: "image/svg+xml;charset=utf-8",
    });
    const svgUrl = URL.createObjectURL(svgBlob);

    const img = new Image();
    img.onload = () => {
      // Create a canvas to draw the image
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      // Save canvas as a PNG file
      canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `QRCode_${studentData?.name}.png`;
        link.click();
        URL.revokeObjectURL(url);
      }, "image/png");

      URL.revokeObjectURL(svgUrl);
    };
    img.src = svgUrl;
    setShowQRCode(!showQRCode);
  };

  return (
    <div className="containerDetail ">
      <div>
        <div className="containerDetail__card ">
          <div className="containerDetail__card__left">
            <div className="containerDetail__card__left__image">
              {studentData?.avatar ? (
                <img
                  src={process.env.REACT_APP_ENDPOINT + studentData?.avatar}
                ></img>
              ) : (
                <Avatar icon={<UserOutlined size={80} />} />
              )}
            </div>
            <div className="containerDetail__card__left__content">
              <p>{`${t("search.fullName")} ${studentData?.name}`}</p>

              <p>{`${t("search.belt")}${studentData?.infoBelt?.name}`}</p>

              <p>{`${t("search.branch")}${studentData?.branch?.name} / ${
                studentData?.infoClub?.name
              } `}</p>
            </div>
          </div>
          <div className="containerDetail__card__right">
            <Row gutter={[16, 16]}>
              <Col md={8} sm={8} xs={6}>
                <Button
                  className="containerDetail__card__right__button"
                  onClick={() => handleCopyLink(studentData?.user_id)}
                >
                  <LinkOutlined />
                </Button>
              </Col>
              <Col md={8} sm={8} xs={6}>
                <Button
                  className="containerDetail__card__right__button"
                  onClick={handleShowQRCode}
                >
                  <QrcodeOutlined />
                </Button>
              </Col>
              <Col md={8} sm={8} xs={6}>
                <Button
                  className="containerDetail__card__right__button"
                  onClick={() => handleOpenTable(studentData?.id)}
                >
                  {t("search.detail")}
                </Button>
              </Col>
            </Row>
          </div>
        </div>

        {openTable?.key === studentData?.id && openTable?.status && (
          <Table
            columns={baseColumn}
            dataSource={dataAchievements}
            locale={{ emptyText: t("common.nullData") }}
            rowKey={(record) => record.id}
            pagination={false}
          ></Table>
        )}
      </div>
      {showQRCode && (
        <Modal
          onCancel={handleShowQRCode}
          className="modalqrcode"
          footer={null}
          visible={showQRCode}
          keyboard
          getContainer={false}
          centered
          title="QR Code"
          width={265}
        >
          <div className="qrcode" ref={qrRef}>
            <QRCode
              value={`${window.location.origin}/student-search/${studentData?.user_id}`}
              size={128}
            />
            <Button onClick={downloadQRCode} style={{ marginTop: "20px" }}>
              {t("search.downloadQr")}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default StudentSearchDetail;
